import React from "react";
import ReactPlayer from "react-player"
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import Logo from "../../components/Logo/Logo.js";
import MultiCard from "../../components/MultiCard/MultiCard.js";

import { bugs, website, server } from "../../variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "../../variables/charts.js";

import styles from "../../assets/jss/material-dashboard-react/views/foreningStyle.js";
const forreningArticles = require('../../assets/articles/Forening/ForeningArtiklar.json');

const useStyles = makeStyles(styles);

export default function Forening() {
  const classes = useStyles();

  return (
    <GridContainer>
      <Logo />
      <MultiCard source="Forening/" jsonArray={forreningArticles.articles} />
    </GridContainer>
  );
}