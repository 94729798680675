import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/imageParagraphStyle.js";

const useStyles = makeStyles(styles);

export default function ImageParagraph(props) {
  const classes = useStyles();
  const { paragraphObject, source, ...rest } = props;
  let image = require('../../assets/img/' + source + paragraphObject.content);

  return (
    <div className={classes.centeredImage}><img src={image} alt={paragraphObject.content} className={classes.imageObject}></img></div>
  );
}

ImageParagraph.propTypes = {
  paragraphObject: PropTypes.object,
  source: PropTypes.string
};