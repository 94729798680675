/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Icons
import Home from "@material-ui/icons/Home"; // hem
import Notifications from "@material-ui/icons/Notifications";
import Hema from "./assets/icons/HemaIcon.js"; // hema
import Msl from "./assets/icons/MslIcon.js"; // foreningen
import ContactMail from "@material-ui/icons/ContactMail"; // kontakt
import CalendarToday from "@material-ui/icons/CalendarToday"; // kalender
import Sparring from "./assets/icons/SparringIcon.js"; // traning
import Prize from "@material-ui/icons/EmojiEvents"; // SM


// core components/views for Admin layout
import HomePage from "./views/Home/Home.js";
import NyheterPage from "./views/Nyheter/Nyheter.js";
import SMPage from "./views/SM/SM.js";
import HemaPage from "./views/Hema/Hema.js";
import ForeningPage from "./views/Forening/Forening.js";
import TraningPage from "./views/Traning/Traning.js";
import KontaktPage from "./views/Kontakt/Kontakt.js";
import KalenderPage from "./views/Kalender/Kalender.js";



const homeRoutes = [
  {
    path: "/home",
    name: "Hem",
    icon: Home,
    component: HomePage,
    layout: "/"
  },
  {
    path: "/nyheter",
    name: "Nyheter",
    icon: Notifications,
    component: NyheterPage,
    layout: "/"
  },
  {
    path: "/sm",
    name: "SM 2022",
    icon: Prize,
    component: SMPage,
    layout: "/"
  },
  {
    path: "/hema",
    name: "Om HEMA",
    icon: Hema,
    component: HemaPage,
    layout: "/"
  },
  {
    path: "/forening",
    name: "Om Föreningen",
    icon: Msl,
    component: ForeningPage,
    layout: "/"
  },
  {
    path: "/traning",
    name: "Om Träningen",
    icon: Sparring,
    component: TraningPage,
    layout: "/"
  },
  {
    path: "/kalender",
    name: "Kalender",
    icon: CalendarToday,
    component: KalenderPage,
    layout: "/"
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    icon: ContactMail,
    component: KontaktPage,
    layout: "/"
  },
];

export default homeRoutes;
