import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import SimpleLink from "../../components/MultiCard/SimpleLink.js";

export default function LinkParagraph(props) {
  const { paragraphObject, ...rest } = props;

  return (
    <p>
      <SimpleLink paragraphObject={paragraphObject}/>
    </p>
  );
}

LinkParagraph.propTypes = {
  paragraphObject: PropTypes.object
};