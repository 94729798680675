import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "../../material-dashboard-react.js";

const imageParagraphStyle = {
  centeredImage: {
    display: "flex",
    justifyContent: "center"
  },
  imageObject: {
    width: "100%",
    height: "auto"
  }
};

export default imageParagraphStyle;
