/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/logoStyle.js";
import { ReactComponent as MainLogo } from "../../assets/img/msl-light-bg-text-cleared.svg";

const useStyles = makeStyles(styles);

export default function Logo(props) {
  const classes = useStyles();
  return (
      <div className={classes.container}>
		<MainLogo width="100%" height="100%" />
      </div>
  );
}

