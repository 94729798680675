import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "../../material-dashboard-react.js";

const videoParagraphStyle = {
  playerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default videoParagraphStyle;
