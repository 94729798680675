import React from 'react';

const HemaIcon = ({
  style = {},
  fill = "#ffffff",
  width = "100%",
  className = "",
  viewBox = "0 0 210 297"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
	xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M178.81 225.64c-6.746-3.168-9.53-5.125-10.937-7.689-7.28-13.27-13.54-22.96-17.907-27.731l-5.083-5.552-16.79-.046c-21.67-.058-20.59.123-25.786-4.324l-4.466-3.823-6.94 7.169c-3.818 3.943-7.092 7.83-7.275 8.64-.183.81 2.646 4.657 6.289 8.55 3.642 3.895 6.622 7.8 6.622 8.68 0 1.716-4.366 4.137-7.463 4.137-1.04 0-5.31-3.445-9.49-7.656-4.178-4.21-8.262-7.656-9.076-7.656-.814 0-6.096 4.262-11.738 9.47-7.296 6.736-10.464 10.447-10.972 12.851-1.147 5.434-4.523 8.302-9.774 8.302-5.759 0-9.296-3.367-9.296-8.85 0-5.16 2.503-8.328 7.842-9.927 3.532-1.058 6.42-3.52 13.631-11.62 5.033-5.653 9.15-10.868 9.15-11.59 0-.722-3.438-5.162-7.64-9.868s-7.501-8.867-7.33-9.247c2.128-4.752 3.607-6.706 5.073-6.706.97 0 4.685 2.953 8.257 6.562 3.571 3.609 6.919 6.562 7.439 6.562 1.319 0 19.356-16.538 19.356-17.747 0-.543-1.476-2.23-3.28-3.748-4.356-3.665-4.14-5.366 1.019-7.997 2.365-1.207 4.586-2.656 4.935-3.222.352-.568-3.057-1.623-7.632-2.36-7.429-1.2-8.595-1.732-11.498-5.253-1.777-2.156-3.23-4.562-3.23-5.347 0-.786 1.722-2.445 3.828-3.687 2.105-1.242 3.828-2.654 3.828-3.137 0-.483-2.947-1.22-6.55-1.638-7.302-.848-6.976-.587-17.949-14.354-3.894-4.886-10.556-12.632-14.805-17.215C22.38 76.933 22.7 75.562 35.13 80.195c21.394 7.974 42.414 20.424 67.217 39.813 5.865 4.584 11.059 8.335 11.543 8.335s11.954-10.758 25.49-23.905c20.24-19.661 25.883-24.532 31.778-27.434 3.943-1.941 8.33-3.365 9.748-3.164 2.28.323 2.54.862 2.237 4.638-.65 8.114-4.964 13.933-28.85 38.915-12.817 13.406-23.305 24.995-23.305 25.753 0 .758 5.786 7.724 12.857 15.48 21.36 23.431 38.597 48.549 44.615 65.014 1.174 3.211 2.135 5.968 2.135 6.127 0 .95-3.821-.387-11.785-4.127z"
    />
  </svg>
);

export default HemaIcon;
