import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import TextParagraph from "../../components/MultiCard/TextParagraph.js";
import VideoParagraph from "../../components/MultiCard/VideoParagraph.js";
import ImageParagraph from "../../components/MultiCard/ImageParagraph.js";
import ListParagraph from "../../components/MultiCard/ListParagraph.js";
import LinkParagraph from "../../components/MultiCard/LinkParagraph.js";

import styles from "../../assets/jss/material-dashboard-react/components/multiCardStyle.js";

const useStyles = makeStyles(styles);

export default function MultiCard(props) {
  const classes = useStyles();
  const { source, jsonArray, ...rest } = props;
  let container = [];

  jsonArray.map((article, index) => {
    let paragraphs = [];

    article.paragraphs.map((paragraph, index) =>{
        if(paragraph.type === "image"){
            paragraphs.push(<ImageParagraph key={index} paragraphObject={paragraph} source={source} />);
        } else if(paragraph.type === "video"){
            paragraphs.push(<VideoParagraph key={index} paragraphObject={paragraph}/>);
        } else if(paragraph.type === "text"){
            paragraphs.push(<TextParagraph key={index} paragraphObject={paragraph}/>);
        } else if(paragraph.type === "list"){
            paragraphs.push(<ListParagraph key={index} paragraphObject={paragraph}/>);
        } else if(paragraph.type === "link"){
            paragraphs.push(<LinkParagraph key={index} paragraphObject={paragraph}/>);
        }
    });

    if("visibleCardHeader" in article && article.visibleCardHeader === false ){
        container.push(
          <GridItem xs={12} sm={12} md={12} key={index}>
              <Card>
                <CardBody>
                    {paragraphs}
                </CardBody>
              </Card>
          </GridItem>
      );
    } else {
      container.push(
          <GridItem xs={12} sm={12} md={12} key={index}>
              <Card>
                  <CardHeader color="primary">
                      {article.title ? <h4 className={classes.cardTitleWhite}>{article.title}</h4> : null}
                      {article.subTitle ? <p className={classes.cardCategoryWhite}>{article.subTitle}</p> : null}
                  </CardHeader>
                  <CardBody>
                      {paragraphs}
                  </CardBody>
              </Card>
          </GridItem>
      );
    }
});

  return (
    container
  );
}

MultiCard.propTypes = {
  source: PropTypes.string,
  jsonArray: PropTypes.array
};