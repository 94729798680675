import React from "react";
import ReactPlayer from "react-player"
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import TextWithLinks from "../../components/MultiCard/TextWithLinks.js";
import TextFormatter from "../../components/MultiCard/TextFormatter.js";

export default function TextParagraph(props) {

  const { paragraphObject, ...rest } = props;
  let paragraph = [];

  if("links" in paragraphObject && paragraphObject.links.length){
    let htmlObject = <TextWithLinks textObject={paragraphObject}/>;
    paragraph.push(<TextFormatter paragraphObject={paragraphObject} htmlObject={htmlObject}/>);
  } else {
    let htmlObject = <text>{paragraphObject.content}</text>;
    paragraph.push(<TextFormatter paragraphObject={paragraphObject} htmlObject={htmlObject}/>);
  }

  return (
    paragraph
  );
}

TextParagraph.propTypes = {
  paragraphObject: PropTypes.object
};