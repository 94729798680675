import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import MultiCard from "../../components/MultiCard/MultiCard.js";

import { bugs, website, server } from "../../variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "../../variables/charts.js";

import styles from "../../assets/jss/material-dashboard-react/views/traningStyle.js";
const traningArticles = require('../../assets/articles/Traning/TraningArtiklar.json');

const useStyles = makeStyles(styles);

export default function Traning() {
  const classes = useStyles();
  return (
    <GridContainer>
      <MultiCard source="Traning/" jsonArray={traningArticles.articles} />
    </GridContainer>
  );
}
