import React from "react";
import ReactPlayer from "react-player"
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/videoParagraphStyle.js";

const useStyles = makeStyles(styles);

export default function VideoParagraph(props) {
  const classes = useStyles();
  const { paragraphObject, ...rest } = props;

  return (
    <div className={classes.playerWrapper}>
        <ReactPlayer
          url={paragraphObject.url}
          controls
        />
    </div>
  );
}

VideoParagraph.propTypes = {
  paragraphObject: PropTypes.object
};