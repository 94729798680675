import React from "react";
import ReactPlayer from "react-player"
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Table from "../../components/Table/Table.js";
import Tasks from "../../components/Tasks/Tasks.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import Danger from "../../components/Typography/Danger.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Logo from "../../components/Logo/Logo.js";
import MultiCard from "../../components/MultiCard/MultiCard.js";

import { bugs, website, server } from "../../variables/general.js";

import styles from "../../assets/jss/material-dashboard-react/views/homeStyle.js";
const homeArticles = require('../../assets/articles/Home/HomeArtiklar.json');

const useStyles = makeStyles(styles);

export default function Home() {
  const classes = useStyles();
  return (
    <GridContainer>
      <Logo />
      <MultiCard source="Home/" jsonArray={homeArticles.articles} />
    </GridContainer>
  );
}
