import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import SimpleLink from "../../components/MultiCard/SimpleLink.js";
import TextWithLinks from "../../components/MultiCard/TextWithLinks.js";

export default function ListParagraph(props) {
  const { paragraphObject, ...rest } = props;
  let paragraph = <div/>;
  let items = [];

  paragraphObject.content.map((item, index) =>{
    if(typeof(item) === 'string') {
        items.push(<li key={index}>{item}</li>);
    } else {
        if(item.type === "text"){
            items.push(<li key={index}><TextWithLinks textObject={item}/></li>);
        } else if(item.type === "link"){
            items.push(<li key={index}><SimpleLink key={index} paragraphObject={item}/></li>);
        }
    }
  });

  if(paragraphObject.listType === "ordered"){
    paragraph = <ol>{items}</ol>;
  } else if(paragraphObject.listType === "unordered"){
    paragraph = <ul>{items}</ul>;
  }

  return (
    paragraph
  );
}

ListParagraph.propTypes = {
  paragraphObject: PropTypes.object
};