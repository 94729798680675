import React from "react";
import ReactPlayer from "react-player"
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

export default function TextFormatter(props) {

  const { paragraphObject, htmlObject, ...rest } = props;
  let paragraph = [];

  if("size" in paragraphObject && paragraphObject.size !== ""){
    if(paragraphObject.size === "large"){
      paragraph.push(<h2>{htmlObject}</h2>);
    } else if(paragraphObject.size === "medium"){
      paragraph.push(<h5>{htmlObject}</h5>);
    } else if(paragraphObject.size === "normal"){
      paragraph.push(<p>{htmlObject}</p>);
    } else {
      paragraph.push(<p>{htmlObject}</p>);
    }
  } else {
    paragraph.push(<p>{htmlObject}</p>);
  }

  return (
    paragraph
  );
}

TextFormatter.propTypes = {
  paragraphObject: PropTypes.object,
  htmlObject: PropTypes.object
};