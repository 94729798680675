import React from 'react';

const SparringIcon = ({
  style = {},
  fill = "#ffffff",
  width = "100%",
  className = "",
  viewBox = "0 0 210 297"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
	xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <ellipse
      fill={fill}
      cx="38.731613"
      cy="127.29475"
      rx="8.2139044"
      ry="10.339857" />
    <ellipse
      fill={fill}
      cx="147.14018"
      cy="126.88614"
      rx="8.2139044"
      ry="10.339857" />
    <path
      fill={fill}
      d="m 123.97448,133.94812 4.84747,-21.62613 -1.91717,0.95463 1.95467,3.53343 -0.86394,3.26315 -2.56393,-4.96872 -63.651544,32.98762 -5.040941,1.29792 3.750304,-3.72563 61.401501,-36.86593 -4.38346,-8.23974 2.0262,-1.041397 5.06575,9.116087 1.90511,-1.01111 c 0,0 -0.52603,-3.96831 1.99627,-5.19114 3.01337,-1.46089 6.40155,0.69482 6.40155,0.69482 l 2.14245,4.29868 -4.28664,23.22455 6.94719,9.86197 17.14394,-1.49697 8.66802,-19.79546 -27.42269,-12.43973 -1.04982,0.64564 -2.14245,-4.29868 0.95473,-0.5273 c 0,0 -0.90291,-3.204581 2.21924,-4.916184 2.57026,-1.409048 5.14771,0.840257 5.14771,0.840257 l 2.40388,-1.289292 2.45848,3.952689 -1.14538,0.73105 29.34073,13.66926 -13.12011,34.9173 0.7745,22.33645 44.86073,39.25313 c 0,0 2.37923,4.20987 -0.72182,7.21714 -2.96291,2.87333 -7.32729,1.01331 -7.32729,1.01331 l -44.83083,-35.81605 -12.72885,12.92097 25.69906,26.99686 c 0,0 1.47925,3.60756 -1.70241,6.5396 -3.33018,3.0689 -6.99512,1.2469 -6.99512,1.2469 l -34.0845,-33.10797 18.31268,-24.40352 -6.05167,-20.68512 z" />
    <path
      fill={fill}
      d="M 26.215325,154.02128 8.2888886,162.57081 4.5564851,138.3748 3.9785413,139.43282 0.31435934,136.69627 2.5394282,133.26671 2.330222,129.7636 c 0,0 0.068179,-4.91198 2.573341,-5.57619 2.0278406,-0.53766 3.2803456,1.30484 3.2803456,1.30484 l 0.758326,-1.07961 3.2272764,3.34457 -0.907011,1.18071 4.108205,21.02716 9.446697,-5.87245 -12.647891,-16.33542 -3.2272764,-3.34457 -1.263853,-2.33853 c 0,0 -1.075135,-3.17277 1.600252,-4.65086 1.5156714,-0.83738 4.4526594,0.27041 4.4526594,0.27041 0.21467,-0.31005 0.429328,-0.62011 0.643998,-0.93016 l -7.3163094,-5.34371 1.731877,-2.22033 5.8399894,4.20824 C 30.596093,91.057556 47.173846,71.187389 61.411505,51.271506 l 4.295201,-2.992796 -1.506405,4.921829 -43.055524,64.613651 6.076776,4.45197 -1.627333,2.16303 -7.658066,-5.31648 -0.958671,1.39752 16.132679,19.44551 6.784491,2.15117 4.522975,-2.26149 6.265979,7.7332 -4.721554,4.95322 1.650312,19.08672 35.371942,50.9533 c 0,0 1.240061,5.06268 -1.853342,7.46293 -3.33922,2.59097 -8.626755,-1.23004 -8.626755,-1.23004 l -33.805371,-44.50172 -21.689438,16.58886 5.01113,24.65991 c 0,0 -2.168763,5.37115 -6.066034,6.46111 -3.803758,1.06379 -6.3638954,-2.92685 -6.3638954,-2.92685 L 3.352207,193.96951 27.759749,174.76063 Z" />
  </svg>
);

export default SparringIcon;
