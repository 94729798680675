import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

export default function SimpleLink(props) {
  const { paragraphObject, ...rest } = props;
  let altString = paragraphObject.altString ? paragraphObject.altString : paragraphObject.href;
  return (
    <a href={paragraphObject.href} target="_blank">{altString}</a>
  );
}

SimpleLink.propTypes = {
  paragraphObject: PropTypes.object
};