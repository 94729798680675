import React from "react";
import ReactPlayer from "react-player"
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import SimpleLink from "../../components/MultiCard/SimpleLink.js";

export default function TextWithLinks(props) {

  const { textObject, ...rest } = props;
  let textWithLinks = [];
  let strings = textObject.content.split("<link>");
  let i = 0;
  strings.map((stringPart, index) =>{
    textWithLinks.push(<text>{stringPart}</text>);
    if(i < textObject.links.length){
      textWithLinks.push(<SimpleLink paragraphObject={textObject.links[i]}/>);
    }
    i++;
  });

  return (
    textWithLinks
  );
}

TextWithLinks.propTypes = {
  textObject: PropTypes.object
};